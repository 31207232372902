import React from 'react'
import Whatsapp from '../assets/img/whatsapp.inline.svg'
import Vk from '../assets/img/vk.inline.svg'
import Instagram from '../assets/img/instagram.inline.svg'
import Telegram from '../assets/img/telegram.inline.svg'
import Youtube from '../assets/img/youtube.inline.svg'
import { graphql, useStaticQuery } from 'gatsby'

export default function Social() {
  const data = useStaticQuery(graphql`
    query {
      configJson {
        instagram
        telegram
        whatsapp
        vk
        youtube
      }
    }
  `)

  return (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        href={data.configJson.instagram}
        style={{ '&:hover': { transform: 'scale(1.05)' } }}
      >
        <Instagram style={{ marginRight: '10px' }} />
      </a>
      <a target="_blank" rel="noreferrer" href={data.configJson.telegram}>
        <Telegram style={{ marginRight: '10px' }} />
      </a>
      <a target="_blank" rel="noreferrer" href={data.configJson.whatsapp}>
        <Whatsapp style={{ marginRight: '10px' }} />
      </a>
      <a target="_blank" rel="noreferrer" href={data.configJson.vk}>
        <Vk style={{ marginRight: '10px' }} />
      </a>
      <a target="_blank" rel="noreferrer" href={data.configJson.youtube}>
        <Youtube style={{ transform: 'scale(0.7)', fill: 'red' }} />
      </a>
    </>
  )
}
