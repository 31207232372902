import React from 'react'
import * as classes from './footer.module.scss'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Social from '../../social'
import Modal from '../../modal'

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      configJson {
        logo {
          childImageSharp {
            gatsbyImageData(width: 140, placeholder: BLURRED, quality: 99, formats: [AUTO, WEBP])
          }
        }
        text
        phone
        phoneLink
        mail
        town
      }
      allFooterJson {
        nodes {
          title
          items {
            title
            href
          }
        }
      }
    }
  `)

  return (
    <footer id="footer">
      <Container>
        <div className={classes.inner}>
          <div className={`d-flex`}>
            <div className={classes.first}>
              <Link to="/">
                <GatsbyImage image={getImage(data.configJson.logo)} alt={`logo`} />
              </Link>
              <p>{data.configJson.text}</p>
            </div>
            {data.allFooterJson.nodes.map((col, index) => {
              return (
                <div key={index} className={classes.column}>
                  <p>{col.title}</p>
                  {col.items.map((item, i) => {
                    return (
                      <Link key={i} to={item.href}>
                        {item.title}
                      </Link>
                    )
                  })}
                </div>
              )
            })}
          </div>
          <div className={classes.last}>
            <p className={`d-none d-md-block`}>Обратная связь</p>
            <div style={{ marginBottom: '22px' }}>
              <Social />
            </div>
            <a href={data.configJson.phoneLink} className={classes.phone}>
              {data.configJson.phone}
            </a>
            <p>{data.configJson.town}</p>
            <p>Краснодар</p>
            <p>Казахстан</p>
            <Modal
              text
              btnStyles={{ padding: 0, color: 'black' }}
              wrapStyles={{ textAlign: 'left' }}
            >
              Нужна помощь?
            </Modal>
          </div>
        </div>
      </Container>
    </footer>
  )
}
