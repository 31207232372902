import React, { useState } from 'react'
import * as classes from './navbar.module.scss'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Social from '../../social'
import Whatsapp from '../../../assets/img/whatsapp.inline.svg'
import Modal from '../../modal'

export default function Navbar({ navBg }) {
  const data = useStaticQuery(graphql`
    query {
      configJson {
        logo {
          childImageSharp {
            gatsbyImageData(width: 70, placeholder: BLURRED, quality: 99, formats: [AUTO, WEBP])
          }
        }
        logoWhite {
          childImageSharp {
            gatsbyImageData(width: 70, placeholder: BLURRED, quality: 99, formats: [AUTO, WEBP])
          }
        }
        text
        phone
        phoneLink
        town
        whatsapp
      }
      allMenuJson {
        nodes {
          name
          url
          items {
            name
            url
          }
        }
      }
    }
  `)

  const [menuShow, setMenuShow] = useState(false)
  const [menuIndex, setMenuIndex] = useState(-1)
  const primary = '#ffc700'

  if (typeof window !== `undefined` && typeof document !== `undefined`) {
    document.addEventListener('scroll', () => {
      if (window.pageYOffset >= 200) {
        document.getElementById('head').classList.add(`navbarFixed`)
        document.getElementById('head').style.backgroundColor = navBg ? navBg : '#fff'
      } else {
        document.getElementById('head').classList.remove(`navbarFixed`)
        document.getElementById('head').style.backgroundColor = 'transparent'
      }
    })
  }

  return (
    <>
      <nav className={classes.navigation}>
        <div className={classes.info} id="info">
          <Container>
            <div className={`d-flex justify-content-between align-items-center`}>
              <div>
                <Social />
              </div>
              <div className={`d-flex align-items-center`}>
                <a href={data.configJson.phoneLink}>{data.configJson.phone}</a>
                <p>{data.configJson.town}</p>
                <p>Краснодар</p>
                <p>Казахстан</p>
              </div>
            </div>
          </Container>
        </div>
        <div className={`${classes.nav} ${navBg ? classes.alt : null}`} id="head">
          <Container>
            <div className={classes.inner}>
              <div className={classes.left}>
                <p className={`d-block d-md-none mb-0`} style={{ marginRight: '16px' }}>
                  Россия
                </p>
                <p className={`d-block d-md-none mb-0`} style={{ marginRight: '16px' }}>
                  Казахстан
                </p>
                <Link to="/">
                  {navBg ? (
                    <img
                      src="https://sruboff-company.ru/banimain/logo-white.png"
                      alt={`logo`}
                      style={{ width: '70px' }}
                    />
                  ) : (
                    <img
                      src="https://sruboff-company.ru/banimain/logo.png"
                      alt={`logo`}
                      style={{ width: '70px' }}
                    />
                  )}
                  <p>{data.configJson.text}</p>
                </Link>
              </div>
              <div className={classes.right}>
                <button
                  className={menuShow ? `${classes.toggler} ${classes.close}` : classes.toggler}
                  onClick={() => setMenuShow(!menuShow)}
                >
                  <span />
                </button>
                <div className={menuShow ? `${classes.menu} ${classes.menuShow}` : classes.menu}>
                  <div
                    className={`d-flex justify-content-between d-md-none`}
                    style={{ marginBottom: '30px' }}
                  >
                    <button
                      className={menuShow ? `${classes.toggler} ${classes.close}` : classes.toggler}
                      onClick={() => setMenuShow(!menuShow)}
                    >
                      <span />
                    </button>
                    <Link to="/">
                      <GatsbyImage
                        image={getImage(data.configJson.logo)}
                        alt={`logo`}
                        style={{ width: '70px' }}
                      />
                    </Link>
                  </div>
                  <div className={`d-md-none`} style={{ marginBottom: '40px', fontSize: '20px' }}>
                    <p style={{ marginBottom: '6px', color: 'black' }}>{data.configJson.town}</p>
                    <p style={{ marginBottom: '6px', color: 'black' }}>Краснодар</p>
                    <p style={{ marginBottom: '6px', color: 'black' }}>Казахстан</p>
                    <div className="mb-2">
                      <a
                        href={data.configJson.phoneLink}
                        style={{
                          color: 'black',
                          borderBottom: '1px dashed black',
                          fontWeight: 700,
                          textDecoration: 'none'
                        }}
                      >
                        {data.configJson.phone}
                      </a>
                    </div>
                    <div>
                      <Modal text btnStyles={{ color: primary }}>
                        Обратный звонок
                      </Modal>
                    </div>
                  </div>
                  {data.allMenuJson.nodes.map((item, index) => {
                    if (Array.isArray(item.items)) {
                      return (
                        <button
                          key={`dropdown-menu-${index}`}
                          onMouseEnter={() => setMenuIndex(index)}
                          onMouseLeave={() => setMenuIndex(-1)}
                        >
                          {item.name}
                          <div
                            className={
                              menuIndex === index
                                ? `${classes.dropdown} ${classes.show}`
                                : `${classes.dropdown}`
                            }
                          >
                            {item.items.map((drpdwnItem, i) => {
                              return (
                                <div key={i}>
                                  <Link to={drpdwnItem.url} onClick={() => setMenuShow(false)}>
                                    {drpdwnItem.name}
                                  </Link>
                                </div>
                              )
                            })}
                          </div>
                        </button>
                      )
                    } else {
                      return (
                        <button key={`menu-${index}`}>
                          <Link to={item.url} onClick={() => setMenuShow(false)}>
                            {item.name}
                          </Link>
                        </button>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </Container>
        </div>
        <a
          href={data.configJson.whatsapp}
          target="_blank"
          rel="noreferrer"
          className={`${classes.whatsapp} ${classes.bounce}`}
        >
          <Whatsapp />
        </a>
      </nav>
    </>
  )
}
