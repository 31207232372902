import React from 'react'
import { Link } from 'gatsby'
import { Container } from 'react-bootstrap'

export default function Brcr({ brcr, brcrAlt }) {
  return (
    brcr && (
      <Container style={{ position: 'relative' }}>
        <div className={brcrAlt ? `brcr alt` : `brcr`}>
          <Link to="/">Главная</Link>
          {brcr.map((item, i) => {
            let link = ''
            if (item === 'Строительство каркасных бань') {
              link = `/modulnye-bani`
            } else if (item === 'Строительство каркасных домов') {
              link = `/modulnye-doma`
            } else if (item === 'Бани Бочки') {
              link = `/bani-bochki`
            }
            return (
              <React.Fragment key={i}>
                <span>/</span>
                {i !== brcr.length - 1 ? <Link to={link}>{item}</Link> : item}
              </React.Fragment>
            )
          })}
        </div>
      </Container>
    )
  )
}
