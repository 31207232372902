import React from 'react'
import * as classes from './modal.module.scss'
import Form from './Form'

export default function MyModal({
  text,
  btnStyles,
  wrapStyles,
  children,
  sendDops,
  banya,
  module
}) {
  const [modal, setModal] = React.useState(false)

  return (
    <div className={classes.modal} style={wrapStyles}>
      <button style={btnStyles} className={text ? classes.text : ''} onClick={() => setModal(true)}>
        {children}
      </button>
      <div
        className={modal ? `${classes.inner} ${classes.show} modal-item-inner` : classes.inner}
        tabIndex={0}
        aria-label="modal-background"
        onClick={() => setModal(false)}
        onKeyDown={() => setModal(false)}
      >
        <div
          tabIndex={0}
          aria-label="modal-content"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <div
            className={classes.close}
            onClick={() => setModal(false)}
            onKeyDown={() => setModal(false)}
            role="button"
            tabIndex={0}
            aria-label="modal-close"
          />
          <h2>Обратная связь</h2>
          <Form sendDops={sendDops} banya={banya} module={module} />
        </div>
      </div>
    </div>
  )
}
