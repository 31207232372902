import React from 'React'

import Seo from './templateBlocks/Seo'
import Navbar from './templateBlocks/navbar/Navbar'
import Brcr from './templateBlocks/Bbrcr'
import Footer from './templateBlocks/footer/Footer'

export default function Layout({ children, title, desc, keywords, navBg, brcr, brcrAlt }) {
  return (
    <>
      <Seo lang={'ru'} title={title} description={desc} keywords={keywords} />
      <Navbar navBg={navBg} />
      <Brcr brcr={brcr} brcrAlt={brcrAlt} />
      {children}
      <Footer />
    </>
  )
}
