import React from 'react'
import * as classes from './form.module.scss'
import InputMask from 'react-input-mask'
import { Link } from 'gatsby'
import emailjs from '@emailjs/browser'

export default function Form({ alt, sendDops, banya, module }) {
  const form = React.useRef()
  const [showAlert, setShowAlert] = React.useState(false)
  const [showLoad, setShowLoad] = React.useState(false)

  const sendEmail = (e) => {
    e.preventDefault()

    // validate logic
    const name = form.current[0]
    const tel = form.current[1]
    const mail = form.current[2]
    const mailMask = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    console.log(form.current[0])
    if (name.value.length < 2) {
      name.classList.add('formError')
      setTimeout(() => name.classList.remove('formError'), 2000)
    }
    if (tel.value.replace(/\D/g, '').length < 11) {
      tel.classList.add('formError')
      setTimeout(() => tel.classList.remove('formError'), 2000)
    }
    if (!mailMask.test(mail.value)) {
      mail.classList.add('formError')
      setTimeout(() => mail.classList.remove('formError'), 2000)
    } else {
      console.log('done')

      // emailjs logic
      setShowLoad(true)
      emailjs
        .sendForm('default_service', 'template_1hxex2j', form.current, 'Qae2OVOS_f9NEuKwo')
        .then(
          (result) => {
            console.log(result.text)
            setShowLoad(false)
            setShowAlert(true)
          },
          (error) => {
            console.log(error.text)
            setShowAlert(true)
            setTimeout(() => setShowAlert(false), 3000)
          }
        )
    }
  }
  return (
    <>
      <form className={classes.form} onSubmit={sendEmail} ref={form}>
        <label>
          Имя
          <input type="text" name="name" />
        </label>
        <label>
          Телефон
          <InputMask
            type="tel"
            name="phone"
            defaultValue="7"
            mask="+9 (999) 999-99-99"
            maskChar=""
          />
        </label>
        <label>
          E-mail
          <input type="mail" name="mail" />
        </label>
        <div className={alt ? `d-none` : `d-block`}>
          <label>
            Комментарии
            <textarea type="text" name="comment" />
          </label>
        </div>
        <div>
          <input type="text" readOnly value={banya} className={'d-none'} name="banya" />
          <input type="text" readOnly value={module} className={'d-none'} name="module" />
          <input type="text" readOnly value={sendDops} className={'d-none'} name="dops" />
          <button type="submit" className={classes.submit}>
            {showLoad ? `Отправка...` : showAlert ? `Ваша заявка успешно отправлена` : `Отправить`}
          </button>
        </div>
        <p className={classes.policy}>
          Нажимая на кнопку, Вы даёте согласие на{' '}
          <Link to="/policy">обработку персональных данных</Link>
        </p>
      </form>
    </>
  )
}
